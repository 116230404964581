import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Button } from "../ui/Button";
import { marked } from "marked";
import posts from "./posts";
import { prettyDate } from "../../utlis/pretty";

const BlogPost = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [similarPosts, setSimilarPosts] = useState([]);

  // Function to find similar posts based on tags
  const findSimilarPosts = (currentPost) => {
    // Skip if no current post or no tags
    if (!currentPost || !currentPost.tags) return [];

    // Filter out the current post and score remaining posts based on tag matches
    const scoredPosts = posts
      .filter((p) => p.slug !== currentPost.slug)
      .map((post) => ({
        ...post,
        score: post.tags
          ? post.tags.filter((tag) => currentPost.tags.includes(tag)).length
          : 0,
      }))
      .filter((post) => post.score > 0) // Only keep posts with matching tags
      .sort((a, b) => b.score - a.score) // Sort by number of matching tags
      .slice(0, 3); // Get top 3 matches

    return scoredPosts;
  };

  useEffect(() => {
    const foundPost = posts.find((p) => p.slug === slug);
    setPost(foundPost);

    // Find similar posts when current post is loaded
    if (foundPost) {
      const similar = findSimilarPosts(foundPost);
      setSimilarPosts(similar);
    }
  }, [slug]);

  if (!post) return <p>Loading...</p>;

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-b from-blue-800 to-blue-500">
        <div className="h-[500px] relative">
          {/* <img
            src="https://public.readdy.ai/ai/img_res/5853fcf8c048fd5e0edd69c6ae576ff6.jpg"
            alt="Hero image"
            className="w-full h-full object-cover"
          /> */}
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent">
            <div className="max-w-7xl mx-auto px-4 h-full flex flex-col justify-end pb-16">
              <div className="max-w-3xl">
                <div className="flex items-center space-x-2 mb-4">
                  {post.tags.map((tag) => (
                    <span
                      key={tag}
                      className="bg-blue-500 text-white px-3 py-1 rounded-full text-sm"
                    >
                      {tag}
                    </span>
                  ))}
                  {/* <span className="text-white/80 text-sm">12 min read</span> */}
                </div>
                <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
                  {post.title}
                </h1>
                <div className="flex items-center space-x-4">
                  {/* <img
                    src="https://public.readdy.ai/ai/img_res/8a3e9471027cd78c15901e1b715783ef.jpg"
                    alt="Author"
                    className="w-12 h-12 rounded-full"
                  /> */}
                  <div>
                    <p className="text-white font-medium">{post.author}</p>
                    <p className="text-white/80 text-sm">
                      {prettyDate(post.publishedDate)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Article Content */}
      <main className="max-w-7xl mx-auto px-4 py-12">
        <div className="max-w-[720px] mx-auto">
          <article className="prose prose-lg max-w-none prose text-lg leading-relaxed lg:prose-xl max-w-none [&>p]:mb-8 [&>h2]:mt-12 [&>h2]:text-2xl [&>h2]:font-semibold [&>h2]:text-gray-800 [&>h1]:mb-8">
            <div
              className="mt-6 text-gray-700"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
          </article>
        </div>
      </main>
      {/* Featured Articles */}
      <section className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-gray-800 mb-8">
            Similar Articles
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {similarPosts.map((article, index) => (
              <Link
                key={index}
                to={`/blog/${article.slug}`}
                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-transform transform hover:scale-105 duration-300 block"
              >
                <div className="p-6">
                  <div className="flex items-center space-x-2 mb-3">
                    {/* <span className="text-sm text-blue-500">
                      {article.tags.join(", ")}
                    </span> */}
                  </div>
                  <h3 className="text-xl font-bold text-gray-800 mb-2 line-clamp-2">
                    {article.title}
                  </h3>
                  <p className="text-gray-600 line-clamp-3">
                    {article.excerpt}
                  </p>
                  <p className="text-blue-600 text-sm">Read More</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogPost;
