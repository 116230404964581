"use client";

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { motion } from "framer-motion";
// import { Button } from "../ui/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
// import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/Accordion";
import {
  Check,
  ArrowRight,
  MessageSquare,
  BarChart,
  Link2,
  Star,
} from "lucide-react";
import { plans } from "../../utlis/plans";
import "swiper/css";
import "swiper/css/pagination";

export default function LandingPage() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState({
    feature1: false,
    feature2: false,
    feature3: false,
  });
  const faqs = [
    {
      id: 0,
      question: "How quickly can I start using SimpleSignCalls?",
      answer:
        "You can start using SimpleSignCalls immediately after signing up. You will choose a local phone number and be able to create codes unique to you.",
    },
    {
      id: 1,
      question: "What happens after I sell the property?",
      answer:
        "You simply move the sign to your next listing and update the message that gets sent when a person text the code.",
    },
    {
      id: 2,
      question: "Do I need special hardware or a new phone number?",
      answer:
        "No special hardware is needed. You choose a dedicated phone number for your SimpleSignCalls service, which prospects will text to receive information.",
    },
    {
      id: 3,
      question: "Can I customize the information sent to prospects?",
      answer:
        "You can customize the property information for each listing in your dashboard. This ensures that prospects receive the most relevant and up-to-date information. It is easy to change so when you move the code, you simply update it with new marketing.",
    },
    {
      id: 4,
      question: "How do I receive leads from SimpleSignCalls?",
      answer:
        "You'll receive email notifications for new leads, allowing you to follow up quickly. You can integrate this directly into your CRM through emailing the leads directly to your CRM or using Zapier.",
    },
    {
      id: 5,
      question: "Does SimpleSignCalls Integrate with my CRM?",
      answer:
        "Yes! You can either use Zapier or use your CRM's email-to-add-a-lead function. Simpily forward the email notification directly to your CRM's provided email and it will get added.",
    },
    {
      id: 6,
      question: "How long is the contract?",
      answer: "We don't have a contract. You can start and cancel at any time.",
    },
  ];

  const testimonials = [
    {
      name: "Jason K",
      location: "Golden Acorn Realty",
      rating: 5,
      quote:
        "I used to asnwer ever call because I never knew if it was potential business. Now I have Simple Sign Calls, I don't worry if someone calls or texts on a sign, they are taken care of.",
      image:
        "https://public.readdy.ai/ai/img_res/f1284608758d3e8a7609cb8ca870a30c.jpg",
    },
    {
      name: "Clay W",
      location: "Red Sign Team",
      rating: 5,
      quote:
        "Our team believes in your listing your lead. It is easy to transfer signs to a new listing, update the message, and then change which agent gets the leads that comes in.",
      image:
        "https://public.readdy.ai/ai/img_res/f72fe0d2e5c3369247bc05d806d169cd.jpg",
    },
    {
      name: "Joey S",
      location: "Keller Williams",
      rating: 5,
      quote:
        "I look everywhere to find a system that didn't charge me a lot as I look to ramp up my real estate business. Simple Sign Calls solves my problems and for a price I like.",
      image:
        "https://public.readdy.ai/ai/img_res/984c94451c656f3a794312eab1ba8b34.jpg",
    },
  ];

  const steps = [
    {
      step: "01",
      title: "Set Up Your Account",
      description:
        "Get a unique, local phone number. You get to chose the area code and phone number you want.",
    },
    {
      step: "02",
      title: "Create a Custom Code",
      description:
        "Create a custom code for each listing - like 1234, House1234, or anything else.",
    },
    {
      step: "03",
      title: "Market Your Code",
      description:
        "Place a sign in your yard or market it on social media. Something like 'Text 1234 to 385-290-4140 to get information on this property.'",
    },
    {
      step: "04",
      title: "Start Capturing Leads",
      description:
        "As people text your custom code, you will receive a notification - that you create and can update at anytime.",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const feature1Position =
        document.getElementById("feature1")?.offsetTop || 0;
      const feature2Position =
        document.getElementById("feature2")?.offsetTop || 0;
      const feature3Position =
        document.getElementById("feature3")?.offsetTop || 0;

      setIsVisible({
        feature1: scrollPosition > feature1Position,
        feature2: scrollPosition > feature2Position,
        feature3: scrollPosition > feature3Position,
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [selectedCodes, setSelectedCodes] = useState(20);

  const calculatePrice = (number) => {
    const plan = plans.find(
      (plan) => number >= plan.minCodes && number <= plan.codes
    );
    return plan ? plan.price : "Contact Us";
  };

  return (
    <div>
      <main>
        {/* Hero Section */}
        <section className="pt-24 pb-16 px-4 bg-gradient-to-r from-indigo-50 to-blue-50">
          <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div>
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 leading-tight mb-6">
                Stop Answering Sign Calls. Automate Buyer Responses in Seconds.
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Transform your property inquiries into qualified leads
                automatically. Our smart system handles calls 24/7, ensuring you
                never miss an opportunity.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <button
                  onClick={() => navigate(`/register`)}
                  className="!rounded-button whitespace-nowrap bg-blue-600 text-white px-8 py-4 text-lg hover:bg-blue-700"
                >
                  Start Free Trial
                </button>
                {/* <button className="!rounded-button whitespace-nowrap border-2 border-indigo-600 text-indigo-600 px-8 py-4 text-lg hover:bg-indigo-50">
                  Watch Video
                </button> */}
              </div>
            </div>
            <div className="relative">
              <img
                src="https://public.readdy.ai/ai/img_res/95e268cde60892b2287f8c4bc311152e.jpg"
                alt="Simple Sign Calls App Interface"
                className="rounded-lg shadow-2xl"
              />
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="py-20 px-4">
          <div className="max-w-7xl mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-4">
                Everything You Need to Convert Sign Calls into Leads
              </h2>
              <p className="text-gray-600 text-lg mb-4">
                Powerful features designed specifically for real estate
                professionals
              </p>
              <Link
                to="/features"
                className="text-indigo-600 hover:text-indigo-700 font-semibold inline-flex items-center"
              >
                View all features <ArrowRight className="w-4 h-4 ml-1" />
              </Link>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Only show top 3 most important features */}
              <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-all">
                <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center mb-4">
                  <MessageSquare className="w-6 h-6 text-indigo-600" />
                </div>
                <h3 className="text-xl font-semibold mb-3">
                  Automated Responses
                </h3>
                <p className="text-gray-600">
                  Instantly send property information to interested buyers 24/7,
                  never missing a lead
                </p>
              </div>

              <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-all">
                <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center mb-4">
                  <BarChart className="w-6 h-6 text-indigo-600" />
                </div>
                <h3 className="text-xl font-semibold mb-3">
                  Real-Time Notifications
                </h3>
                <p className="text-gray-600">
                  Get real-time notifications when a new lead comes in. Never
                  miss a lead.
                </p>
              </div>

              <div className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-all">
                <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center mb-4">
                  <Link2 className="w-6 h-6 text-indigo-600" />
                </div>
                <h3 className="text-xl font-semibold mb-3">CRM Integration</h3>
                <p className="text-gray-600">
                  Seamlessly sync leads with your existing CRM system for
                  streamlined follow-up
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* How It Works */}
        <section id="how-it-works" className="py-20 px-4 bg-gray-50">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-16">
              How It Works
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className="relative h-full flex flex-col flex-grow"
                >
                  <div className="bg-white p-8 rounded-lg shadow-lg flex-grow">
                    <div className="text-4xl font-bold text-indigo-600 mb-4">
                      {step.step}
                    </div>
                    <h3 className="text-xl font-semibold mb-4">{step.title}</h3>
                    <p className="text-gray-600">{step.description}</p>
                  </div>
                  {index < steps.length - 1 && (
                    <div className="hidden md:block absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2">
                      <ArrowRight className="w-8 h-8 text-indigo-300" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* Demo Section */}
        <section className="py-20 px-4">
          <div className="max-w-7xl mx-auto">
            <div className="bg-indigo-600 rounded-2xl p-8 md:p-12">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                <div>
                  <h2 className="text-3xl font-bold text-white mb-6">
                    Try It Now - Text 'DEMO' to Get Started
                  </h2>
                  <p className="text-indigo-100 mb-6">
                    Experience how Simple Sign Calls works in real-time. Send a
                    quick text and see our automated response system in action!
                  </p>
                  <div className="bg-indigo-500/30 rounded-lg p-6 backdrop-blur-sm">
                    <div className="flex flex-col items-center md:items-start">
                      <p className="text-white text-3xl font-bold mb-4">
                        (385) 290-4140
                      </p>
                      {navigator.userAgent.match(/iPhone|Android/i) && (
                        <button
                          onClick={() => {
                            // For mobile devices
                            if (navigator.userAgent.match(/iPhone|Android/i)) {
                              window.location.href =
                                "sms:+13852904140&body=DEMO";
                            }
                            // else {
                            //   // For desktop, copy to clipboard
                            //   navigator.clipboard.writeText("385-290-4140");
                            //   alert("Phone number copied to clipboard!");
                            // }
                          }}
                          className="!rounded-button whitespace-nowrap bg-white text-blue-600 px-8 py-4 text-lg font-semibold hover:bg-blue-50 transition-colors"
                        >
                          Send Demo Text
                        </button>
                      )}
                    </div>
                    <p className="text-indigo-100 text-sm mt-4 text-center md:text-left">
                      👋 Text 'DEMO' to instantly receive a text response with
                      our customizable text
                    </p>
                  </div>
                </div>
                <div className="relative hidden md:block">
                  <img
                    src="https://public.readdy.ai/ai/img_res/4222dc8f0224b24d4c1ed17ca09a8e31.jpg"
                    alt="Demo Preview"
                    className="rounded-lg shadow-xl"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section id="testimonials" className="py-20 px-4 bg-white">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-16">
              What Our Clients Say
            </h2>
            <Swiper
              modules={[Pagination, Autoplay]}
              spaceBetween={30}
              slidesPerView={1}
              pagination={{ clickable: true }}
              autoplay={{ delay: 5000 }}
              breakpoints={{
                640: { slidesPerView: 2 },
                1024: { slidesPerView: 3 },
              }}
              className="p-8 !bg-transparent"
            >
              {testimonials.map((testimonial, index) => (
                <SwiperSlide key={index}>
                  <div className="bg-gray-50 p-8 rounded-lg h-full flex flex-col">
                    <div className="flex items-center mb-4">
                      {/* <img
                        src={testimonial.image}
                        alt={testimonial.name}
                        className="w-12 h-12 rounded-full object-cover mr-4"
                      /> */}
                      <div>
                        <h3 className="font-semibold">{testimonial.name}</h3>
                        <p className="text-gray-600 text-sm">
                          {testimonial.location}
                        </p>
                      </div>
                    </div>
                    <div className="flex text-yellow-400 mb-4">
                      {[...Array(testimonial.rating)].map((_, i) => (
                        <Star key={i} className="w-5 h-5 fill-current" />
                      ))}
                    </div>
                    <p className="text-gray-600 flex-grow">
                      {testimonial.quote}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>

        {/* Pricing Section */}
        {/* <section id="pricing" className="bg-gray-100 dark:bg-gray-700 py-16">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 dark:text-white">
              Custom Pricing
            </h2>
            <div className="max-w-lg mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
              <p className="text-center text-gray-600 dark:text-gray-300 mb-6">
                Choose the number of codes you need, and we'll tailor the
                pricing for your business.
              </p>
              <div className="flex items-center justify-between mb-4">
                <span className="text-sm text-gray-600 dark:text-gray-300">
                  1 Code
                </span>
                <span className="text-sm text-gray-600 dark:text-gray-300">
                  100+ Codes
                </span>
              </div>
              <input
                type="range"
                min="1"
                max="101"
                value={selectedCodes}
                onChange={(e) => setSelectedCodes(e.target.value)}
                className="w-full accent-blue-600"
              />
              <div className="text-center my-4">
                <h3 className="text-2xl font-bold text-gray-800 dark:text-white">
                  {calculatePrice(selectedCodes) === "Contact Us" ? (
                    <>100 Or More</>
                  ) : (
                    <> {selectedCodes} Codes</>
                  )}
                </h3>
                {calculatePrice(selectedCodes) === "Contact Us" ? (
                  <Link
                    to="/contact-us"
                    className="text-lg text-blue-600 dark:text-blue-400 font-semibold"
                  >
                    Contact Us
                  </Link>
                ) : (
                  <p className="text-lg text-blue-600 dark:text-blue-400 font-semibold">
                    ${calculatePrice(selectedCodes)}/mo
                  </p>
                )}
              </div>
              <div className="flex justify-center">
                <button
                  className="!rounded-button whitespace-nowrap border-2 border-white text-white px-8 py-4 text-lg font-semibold bg-blue-600 hover:bg-blue-700"
                  onClick={() => navigate(`/register`)}
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </section> */}

        {/* New Pricing Section */}
        <section id="pricing" className="py-20 px-4 bg-gray-50">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-8">
              Simple, Transparent Pricing
            </h2>
            <p className="text-center text-gray-600 mb-12">
              Simple pricing with no hidden fees. All plans include unlimited
              leads.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
              {/* Starter Plan */}
              <div className="bg-white rounded-lg shadow-lg overflow-hidden transform scale-95">
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">Lite</h3>
                  <div className="flex items-baseline">
                    <span className="text-4xl font-bold">
                      ${calculatePrice(5)}
                    </span>
                    <span className="ml-2 text-sm">/month</span>
                  </div>
                </div>
                <div className="p-6">
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <Check className="w-5 h-5 text-green-500 mr-2" />5 Sign
                      Codes
                    </li>
                    <li className="flex items-center">
                      <Check className="w-5 h-5 text-green-500 mr-2" />
                      Unlimited Leads
                    </li>
                    <li className="flex items-center">
                      <Check className="w-5 h-5 text-green-500 mr-2" />
                      Real-time Analytics
                    </li>
                    <li className="flex items-center">
                      <Check className="w-5 h-5 text-green-500 mr-2" />
                      Integrations
                    </li>
                  </ul>
                  <button
                    className="!rounded-button whitespace-nowrap w-full mt-6 px-6 py-3 border-2 border-blue-600 text-blue-600 hover:bg-blue-50"
                    onClick={() => navigate(`/register`)}
                  >
                    Get Started
                  </button>
                </div>
              </div>

              {/* Standard Plan with Slider */}
              <div className="bg-white rounded-lg shadow-lg overflow-hidden border-2 border-[#2b6cb0] transform scale-110">
                <div className="p-6 bg-[#2b6cb0] text-white">
                  <h3 className="text-xl font-semibold mb-2">Essential</h3>
                  <div className="flex items-baseline">
                    <span className="text-4xl font-bold">
                      ${calculatePrice(selectedCodes)}
                    </span>
                    <span className="ml-2 text-sm">/month</span>
                  </div>
                </div>
                <div className="p-6">
                  <div className="mb-6">
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-sm font-medium text-gray-600">
                        1 Codes
                      </span>
                      <span className="text-sm font-medium text-gray-600">
                        99 Codes
                      </span>
                    </div>
                    <div className="relative">
                      <input
                        type="range"
                        min="1"
                        max="99"
                        value={selectedCodes}
                        onChange={(e) =>
                          setSelectedCodes(parseInt(e.target.value))
                        }
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-[#2b6cb0] relative z-10"
                      />
                      <div className="absolute -top-8 left-0 right-0 flex items-center justify-center pointer-events-none">
                        <div className="animate-bounce bg-[#2b6cb0] text-white text-xs px-2 py-1 rounded-full shadow-lg">
                          Slide for pricing!
                        </div>
                      </div>
                    </div>
                    <p className="text-center text-2xl font-bold text-[#2b6cb0] mt-4">
                      {selectedCodes} Codes
                    </p>
                  </div>
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <Check className="w-5 h-5 text-green-500 mr-2" />
                      Unlimited Leads
                    </li>
                    <li className="flex items-center">
                      <Check className="w-5 h-5 text-green-500 mr-2" />
                      Real-time Analytics
                    </li>
                    <li className="flex items-center">
                      <Check className="w-5 h-5 text-green-500 mr-2" />
                      Integrations
                    </li>
                  </ul>
                  <button
                    className="!rounded-button whitespace-nowrap w-full mt-6 px-6 py-3 bg-blue-600 text-white hover:bg-blue-700"
                    onClick={() => navigate(`/register`)}
                  >
                    Get Started
                  </button>
                  <small>
                    Upgrade or downgrade at any time. <br />
                    No contracts.
                  </small>
                </div>
              </div>

              {/* Professional Plan */}
              <div className="bg-white rounded-lg shadow-lg overflow-hidden transform scale-95">
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">Enterprise</h3>
                  <div className="flex items-baseline">
                    <span className="text-4xl font-bold">Contact Us</span>
                    {/* <span className="ml-2 text-sm">/month</span> */}
                  </div>
                </div>
                <div className="p-6">
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <Check className="w-5 h-5 text-green-500 mr-2" />
                      100+ Sign Codes
                    </li>
                    <li className="flex items-center">
                      <Check className="w-5 h-5 text-green-500 mr-2" />
                      Unlimited Leads
                    </li>
                    <li className="flex items-center">
                      <Check className="w-5 h-5 text-green-500 mr-2" />
                      Real-time Analytics
                    </li>
                    <li className="flex items-center">
                      <Check className="w-5 h-5 text-green-500 mr-2" />
                      Priority Support
                    </li>
                    <li className="flex items-center">
                      <Check className="w-5 h-5 text-green-500 mr-2" />
                      Integrations
                    </li>
                  </ul>
                  <button
                    className="!rounded-button whitespace-nowrap w-full mt-6 px-6 py-3 border-2 border-blue-600 text-blue-600 hover:bg-blue-50"
                    onClick={() => navigate(`/contact-us`)}
                  >
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="faq" className="container mx-auto px-6 py-16">
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 dark:text-white">
            Frequently Asked Questions
          </h2>
          <Accordion
            type="single"
            collapsible
            className="w-full max-w-2xl mx-auto"
          >
            {faqs.map((item, index) => (
              <AccordionItem key={index} value={`item-${index}`}>
                <AccordionTrigger>{item.question}</AccordionTrigger>
                <AccordionContent>{item.answer}</AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </section>

        {/* Final CTA */}
        <section className="py-20 px-4 bg-gradient-to-br from-blue-500 to-blue-600 dark:from-blue-600 dark:to-blue-700">
          <div className="max-w-7xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-white mb-6">
              Ready to Transform Your Lead Generation?
            </h2>
            <p className="text-xl text-indigo-100 mb-8">
              Join thousands of successful real estate agents who trust Simple
              Sign Calls
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <button
                onClick={() => navigate(`/register`)}
                className="!rounded-button whitespace-nowrap bg-white text-blue-600 px-8 py-4 text-lg font-semibold hover:bg-blue-700"
              >
                Start Free Trial
              </button>
              {/* <button className="!rounded-button whitespace-nowrap border-2 border-white text-white px-8 py-4 text-lg font-semibold hover:bg-blue-700">
                Schedule Demo
              </button> */}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
