const livePlans = [
  /**************
   * LIVE PLANS *
   **************/
  {
    //0
    id: 0,
    name: "Lite",
    plan: "price_1MROJWFpzhwdh6WJVH436Uhn",
    price: 15,
    codes: 5,
    time: "monthly",
    minCodes: 0,
    downgrade: false,
  },
  {
    //1
    id: 1,
    name: "Essential",
    plan: "price_1MROJWFpzhwdh6WJkv3Ayb5N",
    price: 40,
    codes: 20,
    time: "monthly",
    minCodes: 6,
    downgrade: true,
  },
  {
    //2
    id: 2,
    name: "Professional",
    plan: "price_1MROJWFpzhwdh6WJA4Y1D4PO",
    price: 75,
    codes: 50,
    time: "monthly",
    minCodes: 21,
    downgrade: true,
  },
  {
    //3
    id: 3,
    name: "Enterprise",
    plan: "price_1MROJWFpzhwdh6WJ2xBdRWua",
    price: 125,
    codes: 100,
    time: "monthly",
    minCodes: 51,
    downgrade: true,
  },
  {
    //4
    id: 4,
    name: "Lite Yearly",
    plan: "price_1MROJWFpzhwdh6WJL8cDrD6I",
    price: 150,
    codes: 5,
    time: "yearly",
    minCodes: 0,
    downgrade: false,
  },
  {
    //5
    id: 5,
    name: "Essential Yearly",
    plan: "price_1MROJWFpzhwdh6WJxxvt5dyt",
    price: 400,
    codes: 20,
    time: "yearly",
    minCodes: 6,
    downgrade: true,
  },
  {
    //6
    id: 6,
    name: "Professional Yearly",
    plan: "price_1MROJWFpzhwdh6WJ2GTLWPuD",
    price: 750,
    codes: 50,
    time: "yearly",
    minCodes: 21,
    downgrade: true,
  },
  {
    //7
    id: 7,
    name: "Enterprise Yearly",
    plan: "price_1MROJWFpzhwdh6WJ1q61LD3s",
    price: 1250,
    codes: 100,
    time: "yearly",
    minCodes: 51,
    downgrade: true,
  },
];
const grandfatherPlans = [
  /**************
   * Grandfater LIVE PLANS *
   **************/
  {
    //0
    id: 0,
    name: "Lite",
    plan: "plan_DVvq8MHVsE9kpz",
    price: 10,
    codes: 5,
    time: "monthly",
    minCodes: 0,
    downgrade: false,
  },
  {
    //1
    id: 1,
    name: "Essential",
    plan: "plan_DVvqBjcL15fjub",
    price: 25,
    codes: 20,
    time: "monthly",
    minCodes: 6,
    downgrade: true,
  },
  {
    //2
    id: 2,
    name: "Professional",
    plan: "plan_DVvqwOhFW2DMa6",
    price: 40,
    codes: 50,
    time: "monthly",
    minCodes: 21,
    downgrade: true,
  },
  {
    //3
    id: 3,
    name: "Professional",
    plan: "plan_DVvrEnG76fdJZG",
    price: 60,
    codes: 100,
    time: "monthly",
    minCodes: 51,
    downgrade: true,
  },
  {
    //4
    id: 4,
    name: "Lite Yearly",
    plan: "plan_DZNW43Etmcrt50",
    price: 110,
    codes: 5,
    time: "yearly",
    minCodes: 0,
    downgrade: false,
  },
  {
    //5
    id: 5,
    name: "Essential Yearly",
    plan: "plan_DaaFy6yevkoEZU",
    price: 275,
    codes: 20,
    time: "yearly",
    minCodes: 6,
    downgrade: true,
  },
  {
    //6
    id: 6,
    name: "Professional Yearly",
    plan: "plan_DaaGsRViSANabh",
    price: 440,
    codes: 50,
    time: "yearly",
    minCodes: 21,
    downgrade: true,
  },
  {
    //7
    id: 7,
    name: "Professional Yearly",
    plan: "plan_DaaOaBSPfiVlFX",
    price: 660,
    codes: 100,
    time: "yearly",
    minCodes: 51,
    downgrade: true,
  },
];
const specialPlans = [
  {
    //8
    id: 8,
    name: "500 for $100",
    plan: "prod_RqYBFijlsDLE7r",
    price: 100,
    codes: 500,
    time: "monthly",
    minCodes: 1,
    downgrade: false,
  },
];
const devPlans = [
  /**************
   * TEST PLANS *
   **************/
  {
    id: 0,
    name: "Lite",
    plan: "plan_DNSuZ60iLzpBqi",
    price: 15,
    codes: 5,
    minCodes: 0,
    time: "monthly",
    downgrade: false,
  }, //0
  {
    id: 1,
    name: "Essential",
    plan: "plan_DNT0UBs8zNoYfr",
    price: 40,
    codes: 20,
    minCodes: 6,
    time: "monthly",
    downgrade: true,
  }, //1
  {
    id: 2,
    name: "Professional",
    plan: "plan_DNT0x3fB1xa1k5",
    price: 75,
    codes: 50,
    minCodes: 21,
    time: "monthly",
    downgrade: true,
  }, //2
  {
    id: 3,
    name: "Enterprise",
    plan: "plan_DNT1jfP71Ku1eN",
    price: 125,
    codes: 100,
    minCodes: 51,
    time: "monthly",
    downgrade: true,
  }, //3
  {
    id: 4,
    name: "Lite Yearly",
    plan: "plan_DX7QJoGv7jcuoA",
    price: 150,
    codes: 5,
    minCodes: 0,
    time: "yearly",
    downgrade: true,
  }, //4
  {
    id: 5,
    name: "Essential Yearly",
    plan: "plan_DaaYQPZo74YTJW",
    price: 400,
    codes: 20,
    minCodes: 6,
    time: "yearly",
    downgrade: true,
  }, //5
  {
    id: 6,
    name: "Professional Yearly",
    plan: "plan_DaaZhINs0gkQGG",
    price: 750,
    codes: 50,
    minCodes: 21,
    time: "yearly",
    downgrade: true,
  }, //6
  {
    id: 7,
    name: "Enterprise Yearly",
    plan: "plan_DaabC7UDj8Eowk",
    price: 1250,
    codes: 100,
    minCodes: 51,
    time: "yearly",
    downgrade: true,
  }, //7
];

let plans = [];
if (process.env.NODE_ENV === "development") {
  plans = devPlans.concat(specialPlans);
} else {
  plans = livePlans.concat(grandfatherPlans).concat(specialPlans);
}

const planIdToName = (id) => {
  for (let i = 0; plans.length > i; i++) {
    if (plans[i].plan === id) {
      return plans[i].name;
    }
  }
};

const planCostById = (id) => {
  for (let i = 0; plans.length > i; i++) {
    if (plans[i].plan === id) {
      return plans[i].price;
    }
  }
};

const planMonthlyIncome = (id) => {
  for (let i = 0; plans.length > i; i++) {
    if (plans[i].plan === id) {
      let price;
      if (plans[i].time === "yearly") {
        price = plans[i].price / 12;
      } else {
        if (plans[i].price > 0) {
          price = plans[i].price;
        } else {
          price = 0;
        }
      }
      return price;
    }
  }
};

const everyPlan = devPlans.concat(
  livePlans.concat(grandfatherPlans.concat(specialPlans))
);

export {
  plans,
  grandfatherPlans,
  livePlans,
  devPlans,
  planIdToName,
  planCostById,
  planMonthlyIncome,
  everyPlan,
};
